export const PATHWAY_NOC = "NOC";
export const PATHWAY_AV_TRIAGE = "AV Triage";
export const PATHWAY_OTHER = "Other";
export const PATHWAYS = [
  {
    label: "HealthDirect - Nurse On Call",
    value: PATHWAY_NOC,
  },
  { label: "AV - Paramedic", value: "AV Para" },
  { label: "AV Triage Services", value: PATHWAY_AV_TRIAGE },
  { label: "AV First Responders", value: "AV FR" },
  { label: "NEPT", value: "NEPT" },
  { label: "NSW Ambulance", value: "AV NSW" },
  { label: "AV - RAN", value: "AV RAN" },
  { label: "Urgent Care Centre", value: "UCC" },
  { label: "Residential Aged Care", value: "RAC" },
  { label: "General Practitioner", value: "GP" },
  { label: "Other HCP", value: PATHWAY_OTHER },
];
