<script>
  import Router from "svelte-spa-router";
  import "./tailwind.svelte";
  import Welcome from "./screens/Welcome.svelte";
  import Form from "./screens/Form.svelte";
  import Emergency from "./screens/Emergency.svelte";
  import Verify from "./screens/Verify.svelte";
  import ThankYou from "./screens/ThankYou.svelte";
  import Error from "./screens/Error.svelte";
  import SmsSent from "./screens/SmsSent.svelte";
  import { handleOnSetDirection } from "./i18n";
  import Notification from "./components/Notification.svelte";
  import { notifications } from "./store/notifications";
  import Header from "./components/Header.svelte";
  import LanguageProvider from "./containers/LanguageProvider.svelte";

  const routes = {
    "/": Welcome,
    "/emergency": Emergency,
    "/form": Form,
    "/verify": Verify,
    "/thank-you": ThankYou,
    "/error": Error,
    "/sms-sent": SmsSent,
  };
</script>

<LanguageProvider>
  <div
  class="p-4 fixed top-0 w-full flex flex-col items-center z-50 stack-4 pointer-events-none"
>
  {#each $notifications as notification}
    <Notification type={notification.type} message={notification.message} />
  {/each}
</div>
<Header />
<Router {routes} />
</LanguageProvider>
